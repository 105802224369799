/* eslint-disable no-bitwise, no-param-reassign, no-underscore-dangle */
import i18next from 'i18next';
import React from 'react';
import { activityAPIs, organizationAPIs, siteAPIs } from '@root/services';
import { removeDuplicate, PLAN_KEY } from '@root/utils';
import moment from 'moment';
import {
  DATE_FORMATS,
  formatDate,
  getEffectiveYesterday,
} from '@root/utils/dateUtils';

export const YESTERDAY = getEffectiveYesterday();

export const FROM_YEAR_MONTH_DEFAULT = formatDate(
  YESTERDAY,
  DATE_FORMATS.YEAR_MONTH,
);

export const MockUpProjectType = [
  'activity.jiraWorkManagement',
  'activity.jiraSoftware',
  'activity.jiraServiceManagement',
  'activity.confluence',
  'activity.allProducts',
];

export const BarChartColors = {
  JIRA_SOFTWARE: '#37b4ae',
  JIRA_WORK_MANAGEMENT: '#9188ec',
  JIRA_SERVICE_MANAGEMENT: '#ffaa01',
  JIRA_PRODUCT_DISCOVERY: '#32bbdf',
  CONFLUENCE: '#E5686F',
};

export const ActiveUsersChartColors = {
  EMPTY: '#cecece',
};

export const InactiveDoughnutChartColor = {
  JIRA_SOFTWARE: 'rgba(55,180,174,0.3)',
  JIRA_WORK_MANAGEMENT: 'rgba(146,135,236,0.3)',
  JIRA_SERVICE_MANAGEMENT: 'rgba(255,169,3,0.3)',
  JIRA_PRODUCT_DISCOVERY: 'rgba(50, 187, 223, 0.3)',
  CONFLUENCE: 'rgba(229,104,111,0.3)',
};

export const ProductKeysList = [
  'jira_software',
  'jira_service_desk',
  'jira_business',
  'jira_product_discovery',
  'confluence',
];

export const ProductLabelKeysList = [
  'activity.jiraSoftware',
  'activity.jiraServiceManagement',
  'activity.jiraWorkManagement',
  'activity.jiraProductDiscovery',
  'activity.confluence',
];

export const ProductItems = [
  {
    label: 'Jira Work Management',
    name: 'jiraWorkManagement',
    key: 'activity.jiraWorkManagement',
    limitKey: 'jiraBusinessLimit',
    planKey: 'jira-core.ondemand',
    type: 'jira_business',
  },
  {
    label: 'Jira Service Management',
    name: 'jiraServiceManagement',
    key: 'activity.jiraServiceManagement',
    limitKey: 'jiraServiceDeskLimit',
    planKey: 'jira-servicedesk.ondemand',
    type: 'jira_service_desk',
  },
  {
    label: 'Jira Software',
    name: 'jiraSoftware',
    key: 'activity.jiraSoftware',
    limitKey: 'jiraSoftwareLimit',
    planKey: 'jira-software.ondemand',
    type: 'jira_software',
  },
  {
    label: 'Jira Product Discovery',
    name: 'jiraProductDiscovery',
    key: 'activity.jiraProductDiscovery',
    limitKey: 'jiraProductDiscoveryLimit',
    planKey: 'jira-product-discovery',
    type: 'jira_product_discovery',
  },
  {
    label: 'Confluence',
    name: 'confluence',
    key: 'activity.confluence',
    limitKey: 'confluenceLimit',
    planKey: 'confluence.ondemand',
    type: 'confluence',
  },
];

export const PlanColors = {
  ENTERPRISE: {
    backgroundColor: 'rgba(146,135,236,0.3)',
    color: '#9188ec',
  },
  PREMIUM: {
    backgroundColor: 'rgba(255,169,3,0.3)',
    color: '#ffaa01',
  },
  STANDARD: {
    backgroundColor: 'rgba(55,180,174,0.3)',
    color: '#37b4ae',
  },
  BASIC: {
    backgroundColor: 'rgb(203, 231, 247)',
    color: '#358dc1',
  },
  BETA: {
    backgroundColor: 'rgb(203, 231, 247)',
    color: '#358dc1',
  },
  FREE: {
    backgroundColor: 'rgb(223 225 230)',
    color: '#6b7283',
  },
};

export const getPlanElement = (planKey) => {
  const planName = planKey?.toUpperCase();
  const style = PlanColors[planName];
  return (
    planName && (
      <div
        className='plan-label'
        style={{
          ...style,
          fontSize: '10px',
          padding: '2px 5px',
          borderRadius: '15px',
          fontWeight: 'normal',
        }}
      >
        {planName}
      </div>
    )
  );
};

export const MAX_BAR_THICKNESS = 60;
export const toCamelCase = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );

export const getFormatDateChart = (date) =>
  `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${
    date.getMonth() + 1
  }`;

export const getFormatDateTo = (date) => {
  const month = date.getMonth();
  const dayDate = date.getDate();
  if (dayDate === 1) {
    const day = new Date(date.getFullYear(), month, 1);
    return `${date.getFullYear()}/${month < 9 ? '0' : ''}${
      month + 1
    }/${'0'}${day.getDate()}`;
  }

  return `${date.getFullYear()}/${month < 9 ? '0' : ''}${month + 1}/${
    dayDate <= 10 ? '0' : ''
  }${dayDate - 1}`;
};

export const getFormatDateUserFrom = (date) =>
  `${date.getFullYear()}/${date.getMonth() < 9 ? '0' : ''}${
    date.getMonth() + 1
  }/01`;

export const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const checkExistValueLimit = (val) => val !== '' && val !== null;

export const getPercentageUserList = (producOptions) => {
  const ProductOptions = {
    'activity.jiraSoftware': 'jira_software',
    'activity.jiraServiceManagement': 'jira_service_desk',
    'activity.jiraWorkManagement': 'jira_business',
    'activity.jiraProductDiscovery': 'jira_product_discovery',
    'activity.confluence': 'confluence',
  };
  let list = [];
  if (producOptions && producOptions.length > 0) {
    producOptions.forEach((option) => {
      if (option in ProductOptions) {
        list.push(ProductOptions[option]);
      }
    });
  } else {
    list = Object.values(ProductOptions);
  }
  return list;
};
const getActiveUserIdsByPeriod = (activeUsers = [], month) => {
  // Consider to be active user if collectedDate >= fromMonth
  // and lastActiveDate >= fromMonth
  const users = activeUsers.filter(
    (user) => moment(user.activeMonth).format('YYYY-MM') === month,
  );

  return users;
};
export const getDatasets = (
  dataChart,
  labels,
  producOptions,
  usersByStatus = [],
  isShowLineChart,
) => {
  const getNumberOfUsers = (productKey, date, userType = 'active') => {
    const data = dataChart.find((item) => item.date === date);
    if (!data || !data[productKey] || data[productKey].length === 0) {
      return null;
    }
    let userIDs = data[productKey].filter(
      (value, index, self) => self.indexOf(value) === index,
    );
    if (userType !== 'all') {
      let selectUserIds = [];
      const usersByStatusProductKey =
        usersByStatus?.find((user) => user.productType === productKey) || [];
      const activeUsersIds = [
        ...new Set(
          getActiveUserIdsByPeriod(
            usersByStatusProductKey?.activeUsers,
            date,
          )?.map((user) => user.accountId),
        ),
      ];
      const inActiveUsersIds = userIDs.filter(
        (id) => !activeUsersIds.includes(id),
      );

      if (userType === 'active') selectUserIds = activeUsersIds;
      else selectUserIds = inActiveUsersIds;

      userIDs = userIDs?.filter((id) => selectUserIds.includes(id));
    }

    return userIDs;
  };
  const createDataset = (
    productionName,
    productKey,
    activeColor,
    inActiveColor,
  ) => {
    if (!isShowLineChart) {
      const activeUsers = {
        label: i18next.t('activity.chartLabelActiveUser', {
          product_name: productionName,
        }),
        data: labels.map((date) => {
          const userIds = getNumberOfUsers(productKey, date, 'active');
          return userIds?.length;
        }),
        borderRadius: 3,
        borderColor: activeColor,
        backgroundColor: activeColor,
        stack: productionName,
      };
      const inactiveUsers = {
        label: i18next.t('activity.chartLabelInactiveUser', {
          product_name: productionName,
        }),
        data: labels.map((date) => {
          const userIds = getNumberOfUsers(productKey, date, 'inactive');
          return userIds?.length;
        }),
        borderRadius: 3,
        borderColor: inActiveColor,
        backgroundColor: inActiveColor,
        stack: productionName,
      };
      return [activeUsers, inactiveUsers];
    }
    return {
      label: productionName,
      data: labels.map((date) => {
        const userIds = getNumberOfUsers(productKey, date, 'all');
        return userIds?.length;
      }),
      borderRadius: 3,
      borderColor: activeColor,
      backgroundColor: activeColor,
      stack: productionName,
    };
  };

  const softwareDataset = createDataset(
    'Jira Software',
    'jira_software',
    BarChartColors.JIRA_SOFTWARE,
    InactiveDoughnutChartColor.JIRA_SOFTWARE,
  );

  const jiraWorkDataset = createDataset(
    'Jira Work Management',
    'jira_business',
    BarChartColors.JIRA_WORK_MANAGEMENT,
    InactiveDoughnutChartColor.JIRA_WORK_MANAGEMENT,
  );

  const serviceDataset = createDataset(
    'Jira Service Management',
    'jira_service_desk',
    BarChartColors.JIRA_SERVICE_MANAGEMENT,
    InactiveDoughnutChartColor.JIRA_SERVICE_MANAGEMENT,
  );

  const productDiscoveryDataset = createDataset(
    'Jira Product Discovery',
    'jira_product_discovery',
    BarChartColors.JIRA_PRODUCT_DISCOVERY,
    InactiveDoughnutChartColor.JIRA_PRODUCT_DISCOVERY,
  );

  const confluence = createDataset(
    'Confluence',
    'confluence',
    BarChartColors.CONFLUENCE,
    InactiveDoughnutChartColor.CONFLUENCE,
  );

  const ProductDataSets = {
    'activity.jiraSoftware': softwareDataset,
    'activity.jiraServiceManagement': serviceDataset,
    'activity.jiraWorkManagement': jiraWorkDataset,
    'activity.jiraProductDiscovery': productDiscoveryDataset,
    'activity.confluence': confluence,
  };
  let datasets = [];
  if (producOptions && producOptions.length > 0) {
    producOptions.forEach((option) => {
      if (option in ProductDataSets) {
        datasets = datasets.concat(ProductDataSets[option]);
      }
    });
  } else {
    datasets = Object.values(datasets).flat(1);
  }
  return datasets;
};

export const getUserLimitAnnotation = (producOptions, userLimit) => {
  const getLimitAnnotation = (yAxis, color) => ({
    type: 'line',
    borderDash: [10, 5],
    yMin: yAxis,
    yMax: yAxis,
    borderColor: LightenDarkenColor(color, 10),
    borderWidth: 2.5,
  });
  const {
    confluenceLimit,
    jiraBusinessLimit,
    jiraServiceDeskLimit,
    jiraSoftwareLimit,
    jiraProductDiscoveryLimit,
  } = userLimit;

  const products = {
    'activity.jiraProductDiscovery': {
      limit: jiraProductDiscoveryLimit,
      color: BarChartColors.JIRA_PRODUCT_DISCOVERY,
      line: 'line1',
    },
    'activity.jiraSoftware': {
      limit: jiraSoftwareLimit,
      color: BarChartColors.JIRA_SOFTWARE,
      line: 'line2',
    },
    'activity.jiraServiceManagement': {
      limit: jiraServiceDeskLimit,
      color: BarChartColors.JIRA_SERVICE_MANAGEMENT,
      line: 'line3',
    },
    'activity.confluence': {
      limit: confluenceLimit,
      color: BarChartColors.CONFLUENCE,
      line: 'line4',
    },
    'activity.jiraWorkManagement': {
      limit: jiraBusinessLimit,
      color: BarChartColors.JIRA_WORK_MANAGEMENT,
      line: 'line5',
    },
  };

  const annotationsLines = {};
  Object.entries(products).forEach(([option, { limit, color, line }]) => {
    if (producOptions.includes(option)) {
      annotationsLines[line] = checkExistValueLimit(limit)
        ? getLimitAnnotation(limit || 0, color)
        : undefined;
    }
  });
  return annotationsLines;
};
export const ExistOrgStatus = {
  EXIST: 'exist',
  NOT_EXIST: 'not_exist',
  PENDING: 'pending',
};

export const getSiteIds = (sites) =>
  sites?.map((site) => site.value).join(',') || '';

// All API keys is invalid
export const isInvalidOrgAPIKey = (apiKeyErrors) =>
  apiKeyErrors &&
  apiKeyErrors.filter((key) => key === 'wrong_key').length ===
    apiKeyErrors.length;
// One of API key error is audit_log_disable
export const isDisabledOrgAuditLog = (apiKeyErrors) =>
  apiKeyErrors && apiKeyErrors.includes('audit_log_disable');
export const checkIsExistOrgKey = async () => {
  const res = await organizationAPIs.getAllOrganization();
  const orgList = res.data.organizations;
  const isExist =
    orgList.filter((i) => !isInvalidOrgAPIKey(i?.apiKeyErrors)).length > 0;
  return isExist ? ExistOrgStatus.EXIST : ExistOrgStatus.NOT_EXIST;
};

export const ACTIVITY_SLUG = {
  productStatistic: 'product-data-statistic',
  userActivity: 'user-activity',
  userInventory: 'user-inventory',
};

export const getProductOptionsByKeys = (keys = []) => {
  let listProduct = [];
  if (keys.includes('jira-servicedesk') || keys.includes('jira_service_desk')) {
    listProduct = [...listProduct, 'activity.jiraServiceManagement'];
  }
  if (keys.includes('jira-software') || keys.includes('jira_software')) {
    listProduct = [...listProduct, 'activity.jiraSoftware'];
  }

  if (
    keys.includes('jira-core') ||
    keys.includes('jira_business') ||
    keys.includes('jira_core')
  ) {
    listProduct = [...listProduct, 'activity.jiraWorkManagement'];
  }
  if (
    keys.includes('jira-product-discovery') ||
    keys.includes('jira_product_discovery')
  ) {
    listProduct = [...listProduct, 'activity.jiraProductDiscovery'];
  }
  if (keys.includes('confluence')) {
    listProduct = [...listProduct, 'activity.confluence'];
  }
  listProduct = removeDuplicate(listProduct);
  return listProduct;
};

export const getProductSiteId = async ({ siteId, type, fromTime }) => {
  let applicationRolesRaw = {};
  let listProduct = [];
  if (siteId) {
    try {
      applicationRolesRaw = await siteAPIs.getAllApplicationRoles(siteId);
    } catch {
      // ignored
    }
    const params = {
      from: fromTime,
      siteId,
    };
    // get application data
    const productTypeRawData = await activityAPIs.getProductTypesActivity(
      params,
    );

    const productTypes = productTypeRawData?.data?.productTypes;
    const role = Array.isArray(applicationRolesRaw.data)
      ? applicationRolesRaw?.data?.map((i) => i.key)
      : [];
    if (
      role.includes('jira-servicedesk') ||
      productTypes.includes('jira_service_desk')
    ) {
      listProduct = [...listProduct, 'activity.jiraServiceManagement'];
    }
    if (
      role.includes('jira-software') ||
      productTypes.includes('jira_software')
    ) {
      listProduct = [...listProduct, 'activity.jiraSoftware'];
    }
    if (
      role.includes('jira-core') ||
      productTypes.includes('jira_business') ||
      productTypes.includes('jira_core')
    ) {
      listProduct = [...listProduct, 'activity.jiraWorkManagement'];
    }
    if (
      role.includes('jira-product-discovery') ||
      productTypes.includes('jira_product_discovery')
    ) {
      listProduct = [...listProduct, 'activity.jiraProductDiscovery'];
    }

    const { data } = await siteAPIs.checkProductPermissionConfluence(siteId);
    const check = data.raw;

    if (check || productTypes.includes('confluence')) {
      listProduct = [...listProduct, 'activity.confluence'];
    }
    if (
      type === ACTIVITY_SLUG.productStatistic &&
      (role.includes('jira-core') ||
        role.includes('jira-servicedesk') ||
        role.includes('jira-product-discovery') ||
        role.includes('jira-software'))
    ) {
      listProduct = [...listProduct, 'activity.jiraWorkManagement'];
      return listProduct;
    }
  }
  return listProduct;
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const getProductPlanKey = (plans = [], productName) => {
  const product = ProductItems.find(
    (item) =>
      item?.label === productName ||
      item?.type === productName ||
      item?.key === productName,
  );
  const allPlan = plans?.filter(({ key }) => key === product?.planKey) || [];
  const enterprisePlan = allPlan.find(
    (plan) => plan?.planKey === PLAN_KEY.enterprise,
  );
  const plan = enterprisePlan || allPlan[0];
  return plan?.planKey;
};

export const NoDataAvailable = () => (
  <div
    style={{
      margin: '10%',
      textAlign: 'center',
      color: 'rgb(69, 82, 108)',
      fontWeight: 'normal',
      fontSize: '20px',
    }}
  >
    {i18next.t('common.noDataAvailable')}
  </div>
);

export const checkAvailableOrgKeys = async (organizationIds) => {
  try {
    const res = await organizationAPIs.checkIsAvailableOrgApiKey({
      organizationIds,
    });
    if (res?.data?.data?.orgHasApiKey) {
      return (
        res.data.data.orgHasApiKey.length === organizationIds.split(',').length
      );
    }
    return false;
  } catch (error) {
    console.log('Error: ', error);
    return false;
  }
};
