import moment from 'moment';

/**
 *  Date formats used in the application.
 * @type {Object}
 * @property {string} DEFAULT - 'YYYY/MM/DD'
 * @property {string} YEAR_MONTH - 'YYYY-MM' - Year and Month only
 * @property {string} FIRST_DAY_MONTH - 'YYYY/MM/01' - First day of month
 * @property {string} DATE_TIME - 'YYYY/MM/DD HH:mm:ss' - Date and Time
 *
 */
export const DATE_FORMATS = {
  DEFAULT: 'YYYY/MM/DD',
  YEAR_MONTH: 'YYYY-MM', // Year and Month only
  FIRST_DAY_MONTH: 'YYYY/MM/01', // First day of month
  DATE_TIME: 'YYYY/MM/DD HH:mm', // Date and Time
};

export const formatDate = (date, format = 'YYYY/MM/DD') => {
  if (!date) return '';
  return moment(date).format(format);
};

/**
 *  Calculate the first day of the previous month.
 *  @param {number} previousMonth - The number of months to subtract from the current date.
 *
 *  @return {string} - The first day of the previous month in the format 'YYYY/MM/01'.
 *
 *  For example:
 *  - if the current date is 2022/09/01 and previousMonth is 1, the result will be 2022/09/01.
 *  - if the current date is 2022/09/15 and previousMonth is 1, the result will be 2022/09/01.
 *  - if the current date is 2022/09/15 and previousMonth is 2, the result will be 2022/08/01.
 *  - if the current date is 2022/09/01 and previousMonth is 2, the result will be 2022/08/01.
 */
export const calculatePreviousMonthDay = (previousMonth) => {
  if (!previousMonth || previousMonth === 0) {
    return moment().format(DATE_FORMATS.FIRST_DAY_MONTH);
  }

  return moment()
    .subtract(previousMonth - 1, 'months')
    .format(DATE_FORMATS.FIRST_DAY_MONTH);
};

/**
 *  Get the current date in UTC format.
 *  @returns {string} - The current date in UTC format.
 */
export const getNowDateUTC = () => moment().utc().format(DATE_FORMATS.DEFAULT);

/**
 * Get the effective "yesterday" date for User Activity, User Inventory, User Statistic Page.
 *
 * - If today is the first day of the month, return today instead of yesterday.
 * - Otherwise, return the previous day.
 *
 * This ensures that the statistic calculation does not reference a non-existent previous month in certain cases.
 *
 *  @return {string} - The effective "yesterday" date in the format 'YYYY/MM/DD'.
 *
 *  For example:
 *  - If today is 2022/09/01, the result will be 2022/09/01.
 *  - If today is 2022/09/15, the result will be 2022/09/14.
 */
export const getEffectiveYesterday = () => {
  // If today is the first day of the month, return today instead of yesterday
  if (moment().date() === 1) {
    return moment().format(DATE_FORMATS.DEFAULT);
  }
  // Otherwise, return yesterday
  return moment().subtract(1, 'days').format(DATE_FORMATS.DEFAULT);
};

/**
 *  Get the list of year and month from a given date to the current date.
 *
 *  @param {string} fromDate - The start date in the format 'YYYY-MM'.
 *  @param {string} format - The format of the date. Default is 'YYYY-MM'.
 *
 *  @returns {Array} - The list of year and month from the start date to the current date.
 *
 *  Example:
 *  - getYearMonthList('2022-01') returns ['2022-01', '2022-02', '2022-03', ..., current year and month]
 *
 */
export const getYearMonthList = (
  fromDate,
  format = DATE_FORMATS.YEAR_MONTH,
) => {
  const result = [];
  const startDate = moment(fromDate, format);
  const endDate = moment();

  while (startDate.isSameOrBefore(endDate, 'month')) {
    result.push(startDate.format(format));
    startDate.add(1, 'month');
  }

  return result;
};

/**
 *  Get the difference in months between two dates.
 *  @param {string} fromDate - The start date in the format 'YYYY-MM'.
 *  @param {string} toDate - The end date in the format 'YYYY-MM'.
 *
 *  @returns {number} - The difference in months between the two dates.
 *
 *  Example:
 *  - getMonthDifference('2022-01', '2022-03') returns 2
 */
export const getMonthDifference = (fromDate, toDate = getNowDateUTC()) => {
  const start = moment(fromDate, DATE_FORMATS.YEAR_MONTH);
  const end = moment(toDate, DATE_FORMATS.YEAR_MONTH);

  return end.diff(start, 'months');
};
