import React, { useEffect, useState } from 'react';
import Select, { components } from '@atlaskit/select';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Field } from '@atlaskit/form';
import { makeStyles, styled } from '@material-ui/styles';
import { Checkbox } from '@atlaskit/checkbox';
import Badge from '@atlaskit/badge';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { checkAllOption, ValueCheckAllOption } from '@root/utils';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
    minWidth: 220,
  },
  fieldBox: {
    display: 'flex',
    alignItems: 'center',
  },
  selectMultipleOrg: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    width: '100%',
    minWidth: 230,
    fontSize: '14px',
    '& > div': {
      minWidth: '200px',
    },
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
}));

const LabelCheckBox = styled('label')({
  '& > label': {
    textWrap: 'nowrap',
  },
});
const firstValidOptionIndexWithCheckAll = 1;
// fix bug #1 DASAFE-3079: has only 1 option organization
const firstOptionOrg = 0;

const NoOptionMessageCustom = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.NoOptionsMessage {...props}>
    <span>{i18next.t('common.noDataAvailable')}</span>
  </components.NoOptionsMessage>
);
const OptionCustom = ({ data, isSelected, ...props }) => {
  const { value, label, isDisabled = false } = data;
  const { t } = useTranslation();
  const {options} = props;
  // Check if there is only one option and that option is 'All'
  if (options.length === 1) {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{t('common.noDataAvailable')}</span>
      </components.NoOptionsMessage>
    );
  }
  return (
    <components.Option {...props}>
      {value === ValueCheckAllOption ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: 600,
            borderBottom: '1px lightgray solid',
            paddingBottom: 10,
          }}
        >
          <Checkbox isChecked={isSelected} />
          <div>{data.label}</div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            fontSize: 14,
          }}
        >
          <Checkbox isChecked={isSelected} isDisabled={isDisabled} />
          {isDisabled ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon primaryColor='#E5686F' />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 4,
                }}
              >
                <div>{label}</div>
                <div style={{ color: '#E5686F', fontSize: 10 }}>
                  {t('common.licenseOutdate')}
                </div>
              </div>
            </div>
          ) : (
            <span>{label}</span>
          )}
        </div>
      )}
    </components.Option>

  );
};

const SelectMultiple = ({
  options,
  values,
  name = 'select-name',
  showMaxValue = 1,
  onMenuClose = () => {},
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles();
  const allOption = checkAllOption();
  const optionWithAll = [allOption, ...options];
  const selectedValues =
    options.length === values.length ? optionWithAll : values;
  const MultiValueCustom = ({ data, ...props }) => {
    const index = values?.findIndex((item) => item.value === data.value);
    if (data.value === ValueCheckAllOption && values.length > 0) {
      return showMaxValue === 1 ? (
        <components.MultiValue {...props}>
          <span>
            {values.length > firstValidOptionIndexWithCheckAll
              ? values[firstValidOptionIndexWithCheckAll].label
              : values[firstOptionOrg]?.label}
          </span>
        </components.MultiValue>
      ) : <></>;
    }
  
    if (index > showMaxValue) {
      return <></>;
    }
  
    if (index === showMaxValue) {
      return (
        <Badge>
          <div style={{ fontWeight: 600 }}>+{values.length - showMaxValue}</div>
        </Badge>
      );
    }
  
    return options.length > showMaxValue ? (
      <components.MultiValue {...props}>
        <span>{data?.label}</span>
      </components.MultiValue>
    ) : <></>;
  };

  return (
    <Select
      className={classes.select}
      options={optionWithAll}
      value={selectedValues}
      onChange={(items) => {
        if (options.length > 0){
          const isAllSelected = items.some(
            (item) => item.value === ValueCheckAllOption,
          );
          const isDeselectAllSelected =
            !isAllSelected && selectedValues.length === optionWithAll.length;
          if (
            !isAllSelected &&
            selectedValues.length === optionWithAll.length &&
            items.length === optionWithAll.length - 1
          ) {
            onChange([optionWithAll[firstValidOptionIndexWithCheckAll]]);
            return;
          }
          if (items?.length === 0 || isDeselectAllSelected) {
            onChange([optionWithAll[firstValidOptionIndexWithCheckAll]]);
            return;
          }
  
          if (isAllSelected && selectedValues.length !== optionWithAll.length) {
            onChange(optionWithAll);
          } else if (
            !isAllSelected &&
            items.length === optionWithAll.length - 1
          ) {
            onChange(optionWithAll);
          } else {
            const validItems = items.filter(
              (item) => item.value !== ValueCheckAllOption,
            );
            onChange(validItems);
          }
        }
      }}
      onMenuClose={onMenuClose}
      components={{
        Option: OptionCustom,
        MultiValue: MultiValueCustom,
        NoOptionsMessage: NoOptionMessageCustom,
      }}
      menuPosition='fixed'
      name={name}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      filterOption={(option, inputValue) => option.label.includes(inputValue)}
      {...props}
    />
  );
};

const SelectMultipleOrganizations = ({
  options,
  values,
  name = 'organizations',
  setValues = () => {},
  onMenuClose = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const onChange = (data) => {
    if (data.length === options.length) {
      setIsAllSelected(true);
      setValues(data);
    } else {
      setIsAllSelected(false);
      setValues(data.length === 0 ? [options[0]] : data);
    }
  };

  const handleCheckAll = () => {
    setIsAllSelected(!isAllSelected);
    const orgs = isAllSelected ? [options[0]] : options;
    setValues(orgs);
  };

  useEffect(() => {
    setValues([options[0]]);
  }, [JSON.stringify(options)]);

  return (
    <div className={classes.selectMultipleOrg}>
      <span>{t('common.atlas.organization')}:</span>
      <SelectMultiple
        options={options}
        values={values}
        name={name}
        onChange={onChange}
        onMenuClose={onMenuClose}
      />
      <LabelCheckBox>
        <Checkbox
          label={t('common.all')}
          isChecked={isAllSelected}
          onClick={handleCheckAll}
        />
      </LabelCheckBox>
    </div>
  );
};

const SelectMultipleField = ({
  name,
  label,
  options,
  values,
  showMaxValue = 1,
  setValues = () => {},
  ...props
}) => {
  const classes = useStyles();
  const validSite = options.filter((option) => !option.isDisabled);

  const handleOnchange = (data) => {
    const isAllSelected = data.some(
      (item) => item.value === ValueCheckAllOption,
    );
    const isDeselectAllSelected = data.length === 0;
    if (isDeselectAllSelected) {
      setValues([validSite[firstValidOptionIndexWithCheckAll]]);
      return;
    }
    if (isAllSelected) {
      setValues(validSite);
      return;
    }
    const validItems = data.filter(
      (item) => item.value !== ValueCheckAllOption,
    );
    setValues(validItems);
  };

  useEffect(() => {
    if (values.length === 0) {
      const selectedSite =
        (validSite && [validSite[firstValidOptionIndexWithCheckAll]]) || [];
      setValues(selectedSite);
    }
  }, []);
  const MultiValueCustom = ({ data, ...props }) => {
    if (data.value === ValueCheckAllOption) return <></>;
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.MultiValue {...props}>
        <span>{data?.label}</span>
      </components.MultiValue>
    );
  };

  return (
    <Field isRequired name={name} label={label} {...props}>
      {({ fieldProps: { isDisabled } }) => (
        <div className={classes.fieldBox}>
          <SelectMultiple
            className={classes.select}
            options={options}
            components={{
              Option: OptionCustom,
              MultiValue: MultiValueCustom,
            }}
            values={values}
            name={name}
            isDisabled={isDisabled}
            showMaxValue={showMaxValue}
            onChange={handleOnchange}
          />
        </div>
      )}
    </Field>
  );
};

export { SelectMultiple, SelectMultipleOrganizations, SelectMultipleField };
